do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "payment_method_form"

  defaults =
    debug: false
    stripe_key: "pk_live_ThF0ut03DNs5w9Ye2AN2wSKO"
    stripe:
      betas: ["payment_intent_beta_3"]
    elements:
      locale: "en"
    card_element:
      style:
        base:
          color: '#32325d'
          fontSize: '16px'
          lineHeight: '20px'
          fontFamily: '"Superstudio", monospace'
          fontSmoothing: 'antialiased'
          borderBottom: '1px solid black'
          '::placeholder':
            color: '#aaa'
        invalid:
          color: '#fa755a'
          iconColor: '#fa755a'

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @init_card()

    init_card: ->
      elements_config = @settings.elements
      elements_config["fonts"] = @get_card_wrapper().data('fonts')

      @stripe = Stripe(@settings.stripe_key, @settings.stripe)
      @elements = @stripe.elements(elements_config)

      @card_element = @elements.create('card', @settings.card_element)
      @card_element.mount('#card')

      @card_element.on "change", (event) =>
        @get_card_number_field_div().removeClass("card__field--error")
        error_text = if event.error then event.error.message else ""
        @set_error_text(error_text)
        @get_submit_button().removeAttr("disabled") if event.complete

      @$element.on "submit", "form", (e) =>
        e.preventDefault()
        @handle_payment().then (result) =>
          if result.error
            @get_card_number_field_div().addClass("card__field--error")
            @set_error_text(result.error.message)
            return false
          else
            @$element.off "submit", "form"
            @get_form().submit()

    # ---------------------------------------------------------------------

    get_form: -> @$element.find('form.new_payment_method_form')
    get_errors_div: -> @$element.find('.card__errors')
    get_card_holder_name: -> @get_card_holder_name_input().val()
    get_card_holder_name_input: -> @$element.find('#payment_method_form_card_holder_name')
    get_card_number_field_div: -> @$element.find(".card__field--number")
    get_card_wrapper: -> @$element.find('.payment_method_form__card')
    get_client_secret: -> @get_card_wrapper().data('secret')
    get_customer_email: -> @get_card_wrapper().data('email')
    get_billing_address: ->
      address = @get_card_wrapper().data('address')
      line1: address.line1
      line2: address.line2
      postal_code: address.postal_code
      city: address.city
      state: address.state
      country: address.country.data.alpha2
    get_submit_button: -> @get_form().find('input[type="submit"]')

    handle_payment: ->
      @stripe.handleCardPayment(
        @get_client_secret(),
        @card_element,
        source_data:
          owner:
            name: @get_card_holder_name()
            email: @get_customer_email()
            address: @get_billing_address()
      )

    set_error_text: (error_text) -> @get_errors_div().text(error_text)

  # ---------------------------------------------------------------------
  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = 'div.payment_method_form'
  $(selector).payment_method_form()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).payment_method_form()
  ).observe document.documentElement, { childList: true, subtree: true }
