import _debounce from "lodash/debounce"

# https://github.com/jquery-boilerplate/jquery-boilerplate
do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "cart_item"

  defaults =
    debug: false

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @element.addEventListener "change", ((e) =>
        @submit_edit_form()
      ), false

      @$element.on "update", (e) => @update(e.content)

    # ---------------------------------------------------------------------

    update: (content) ->
      @$element.replaceWith(content)
      # morphdom(@$element[0], content)

    get_edit_form: -> @$element.find "form.edit_cart_item"

    submit_edit_form: ->
      Rails.fire(@get_edit_form()[0], "submit")

  # ---------------------------------------------------------------------

  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = '.cart_item'
  $(selector).cart_item()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).cart_item()
  ).observe document.documentElement, { childList: true, subtree: true }
