import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["countryInput", "vatNumberInput"]

  connect() {
    this.toggleVatNumberInput()
  }

  toggleVatNumberInput() {
    this.vatNumberInputTarget.style.display = this.selectedCountryHasVatNumber
      ? "block"
      : "none"
  }

  get selectedCountryHasVatNumber() {
    return this.vatNumberCountries.includes(this.countryInputTarget.value)
  }

  get vatNumberCountries() {
    return JSON.parse(this.data.get("vatNumberCountries"))
  }
}
