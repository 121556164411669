# https://github.com/jquery-boilerplate/jquery-boilerplate
do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "product_block"

  defaults =
    debug: false

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @init_variants() if @$element.hasClass "product_block--has_variants"

    init_variants: ->
      @set_variant()
      @$element.on "change", ".product_block__variant_option_select", (e) =>
        @set_variant()

    # ---------------------------------------------------------------------

    get_variants_select: -> @$element.find("#cart_item_variant_id")
    get_variant_options: -> @$element.find(".product_block__variant_option")
    get_variant_option_selects: -> @get_variant_options().find("select")
    get_product_price: -> @$element.find(".product_block__price")
    get_variants: -> @get_variants_select().find("option")

    get_selected_variant: -> @get_variants_select().find(":selected")

    get_variants_for_option: (option, value) ->
      $variants_for_option = @get_variants().filter("[data-#{option}='#{value}']")
      ids = $variants_for_option.toArray().map((variant) -> variant.getAttribute("value"))
      return ids

    get_selected_options: ->
      @get_variant_option_selects().map (i,elem) -> return [[elem.dataset.option, elem.options[elem.selectedIndex].value]]

    get_variant_for_selected_options: ->
      possible_variants = []
      @get_selected_options().each((i, elem) => possible_variants.push @get_variants_for_option(elem[0], elem[1]))
      @intersection(possible_variants)

    set_submit_button_disabled_state: (state) ->
      @$element.find(':input[type="submit"]').prop('disabled', state)

    set_price: (price) ->
      @get_product_price().text(price)

    set_variant: ->
      variant_id = @get_variant_for_selected_options()[0]
      if variant_id
        @set_submit_button_disabled_state(false)
        @get_variants_select().val(variant_id)
        price = @get_selected_variant().data("price")
        @set_price(price)
      else
        @set_price(@$element.data("unavailable"))
        @set_submit_button_disabled_state(true)

    intersection: ->
      result = []
      lists = undefined
      if arguments.length == 1
        lists = arguments[0]
      else
        lists = arguments
      i = 0
      while i < lists.length
        currentList = lists[i]
        y = 0
        while y < currentList.length
          currentValue = currentList[y]
          if result.indexOf(currentValue) == -1
            if lists.filter(((obj) ->
                obj.indexOf(currentValue) == -1
              )).length == 0
              result.push currentValue
          y++
        i++
      result

  # ---------------------------------------------------------------------

  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = 'div.apparatus.block.product_block'
  $(selector).product_block()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).product_block()
  ).observe document.documentElement, { childList: true, subtree: true }
