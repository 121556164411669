import { Controller } from "stimulus"

export default class extends Controller {
  toggle() {
    this.element.classList.toggle("footer_block--fly_out_open")
  }

  close() {
    this.element.classList.remove("footer_block--fly_out_open")
  }
}
