import { Controller } from "stimulus"

export default class extends Controller {
  onItemsCreate(event) {
    this.toggleEmptyClass(false)
    this.quantity = event.detail.quantity
  }

  toggleEmptyClass(toggle = this.isEmpty) {
    this.element.classList.toggle(this.emptyClass, toggle)
  }

  set quantity(value) {
    this.element.innerText = value
  }

  get isEmpty() {
    return this.quantity === 0
  }

  get quantity() {
    return Number.parseInt(this.element.innerText)
  }

  get emptyClass() {
    return this.data.get("emptyClass")
  }
}
