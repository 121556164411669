import { Controller } from "stimulus"
import Flickity from "flickity"
import lazySizes from 'lazysizes'

export default class extends Controller {
  static targets = ["gallery", "pagerTotal", "pagerCurrent", "caption"]

  initialize() {
    this.flickity = new Flickity(this.galleryTarget, {
      cellSelector: 'div.attachment__image',
      draggable: '>1',
      pageDots: false,
      prevNextButtons: '>1',
      wrapAround: true,
      dragThreshold: 10
    })

    this.updatePagerTotal()
    this.updateCaption()
    this.updatePagerCurrent()

    this.flickity.on("change", (event) => {
      this.updateCaption()
      this.updatePagerCurrent()
      this.lazyLoadCurrentSlide()
    })

    window.addEventListener("load", () => {
      this.flickity.resize()
    })
  }

  connect() {
    this.flickity.resize()
  }

  updateCaption() {
    this.captionTarget.innerHTML = this.flickity.selectedElement.dataset.caption
  }

  updatePagerTotal() {
    this.pagerTotalTarget.textContent = this.flickity.slides.length
  }

  updatePagerCurrent() {
    this.pagerCurrentTarget.textContent = this.flickity.selectedIndex + 1
  }

  lazyLoadCurrentSlide() {
    let img = this.flickity.selectedElement.querySelector("img")
    lazySizes.loader.unveil(img)
  }
}
