# https://github.com/jquery-boilerplate/jquery-boilerplate
do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "main_menu_block"

  defaults =
    debug: false

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @$element.on "click", ".main_menu_block__button--toggle_newsletter", (event) =>
        @$element.toggleClass "main_menu_block--newsletter_open"

  # ---------------------------------------------------------------------

  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = 'div.apparatus.block.main_menu_block'
  $(selector).main_menu_block()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).main_menu_block()
  ).observe document.documentElement, { childList: true, subtree: true }
