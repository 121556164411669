import "@asgerb/apparatus"
import "@asgerb/apparatus/package/dist/index.css"

import "@asgerb/apparatus-shop"
import "@asgerb/apparatus-shop/package/dist/index.css"

import "flickity/dist/flickity.min.css"

require.context("../fonts", true)
require.context("../images", true)

import "base"
import "blocks"
import "components"
import "pages"
import "forms"
import "inputs"
import "models"

require("@rails/ujs").start()

import Turbolinks from "turbolinks"
Turbolinks.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const controllers = require.context("../controllers", true, /\.js$/)

application.load(definitionsFromContext(controllers))
