import morphdom from "morphdom"

# https://github.com/jquery-boilerplate/jquery-boilerplate
do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "cart"

  defaults =
    debug: false

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @element.addEventListener "itemsUpdate", (event) =>
        event.stopPropagation()
        @update(event.detail.content)

      @element.addEventListener "itemsDestroy", (event) =>
        event.stopPropagation()
        @update(event.detail.content)

    # ---------------------------------------------------------------------

    get_tbody: -> @get_table()
    get_table: -> @$element.find("table")

    update: (content) -> morphdom(@element, content)

  # ---------------------------------------------------------------------

  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = 'div.cart'
  $(selector).cart()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).cart()
  ).observe document.documentElement, { childList: true, subtree: true }
