do ($ = jQuery, window, document) ->

  # ---------------------------------------------------------------------

  pluginName = "checkout_form"

  defaults =
    debug: false

  # ---------------------------------------------------------------------

  class Plugin

    constructor: (@element, options) ->
      @settings = $.extend {}, defaults, options
      @_defaults = defaults
      @_name = pluginName
      @$element = $(@element)
      @init()

    # ---------------------------------------------------------------------

    init: ->
      @$element.css("min-height", window.innerHeight) if @is_ios()

      @$element.on "click", ".checkout_form__button--toggle_checkout", (e) =>
        @$element.find(".checkout_form__checkout")
          .toggleClass("checkout_form__checkout--opened")

    is_ios: ->
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

  # ---------------------------------------------------------------------
  # A really lightweight plugin wrapper around the constructor,
  # preventing against multiple instantiations
  $.fn[pluginName] = (options) ->
    @each ->
      unless $.data @, "plugin_#{pluginName}"
        $.data @, "plugin_#{pluginName}", new Plugin @, options

# =====================================================================

$ ->

  selector = 'div.checkout_form'
  $(selector).checkout_form()
  new MutationObserver((mutations) ->
    mutations.forEach (mutation) ->
      $(selector).checkout_form()
  ).observe document.documentElement, { childList: true, subtree: true }
