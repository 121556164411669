import { Controller } from "stimulus"
import Flickity from "flickity"

export default class extends Controller {
  static targets = ["gallery"]

  initialize() {
    this.flickity = new Flickity(this.galleryTarget, {
      cellSelector: "div.related_items_block__item_page",
      draggable: false,
      contain: true,
      pageDots: false,
      prevNextButtons: true,
      setGallerySize: false,
      wrapAround: false,
      groupCells: true
    })

    window.addEventListener("load", () => {
      this.flickity.resize()
    })
  }

  connect() {
    this.flickity.resize()
  }
}
