import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["itemPages", "itemPage"]

  connect() {
    if (this.isSignedIn) {
      this.sortable = Sortable.create(this.itemPagesTarget, {
        animation: 150,
        onUpdate: event => this._updateOrder()
      })
    }
  }

  _updateOrder() {
    Rails.ajax({
      type: "POST",
      url: this.data.get("updatePath"),
      data: new URLSearchParams({ item_pages: this.itemPageIds }).toString()
    })
  }

  get itemPageIds() {
    return this.itemPageTargets.map(element => element.dataset.id)
  }

  get isSignedIn() {
    return document.body.classList.contains("user_signed_in")
  }
}
